import React, { Component } from 'react'

export default class but extends Component {
    render() {
        return (
            <div>
                <button></button>
            </div>
        )
    }
}
